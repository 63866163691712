import React from 'react';
import Aboutme from '../components/about/about';

const About = () => {
  return (
    <div>
      <Aboutme />
    </div>
  )
}

export default About