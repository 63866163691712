import React from 'react';
import HomePage from '../components/home/home';

const Home = () => {
  return (
    <div>
      <HomePage />
    </div>
  )
}

export default Home